import * as React from 'react';
import type { DeepMap, FieldError } from 'react-hook-form';

import { ErrorMessage } from './error-message';

type TextareaProps = {
  errors: DeepMap<Record<string, unknown>, FieldError>;
  label: string;
  name: string;
  required?: boolean;
  rows?: number;
  onDark?: boolean;
} & (
  | { description: string; descriptionId: string }
  | { description?: never; descriptionId?: never }
);

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      description,
      descriptionId,
      errors,
      label,
      name,
      required,
      rows = 4,
      onDark,
      ...rest
    },
    ref
  ) => {
    const hasErrors = Boolean(errors?.[name]);
    return (
      <div>
        <div className="flex justify-between">
          <label
            htmlFor={name}
            className="block text-lg font-black font-display"
          >
            {label}
          </label>
          {description ? (
            <span id={descriptionId} className="text-sm text-gray-500">
              {description}
            </span>
          ) : null}
          <ErrorMessage errors={errors} name={name} label={label} />
        </div>
        <div className="mt-1">
          <textarea
            aria-describedby={description ? descriptionId : undefined}
            aria-invalid={hasErrors}
            ref={ref}
            id={name}
            name={name}
            rows={rows}
            defaultValue=""
            required={required}
            className={`mt-0 block w-full px-0.5 border-0 rounded-t-lg border-b-2  focus:ring-0  pb-0 ${
              onDark
                ? 'border-cream text-cream bg-teal focus:bg-teal focus:border-cream'
                : 'border-teal bg-cream focus:bg-cream focus:border-teal'
            }`}
            {...rest}
          />
        </div>
      </div>
    );
  }
);

export { Textarea };
