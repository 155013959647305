import * as React from 'react';

type RadioButtonProps = {
  id: string;
  label: string;
  name: string;
  rest?: unknown[];
};

const Radio = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ id, label, name, ...rest }, ref) => (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          ref={ref}
          id={id}
          name={name}
          value={label}
          type="radio"
          className="w-4 h-4 border-2 border-teal text-teal focus:ring-teal"
          {...rest}
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={id}
          className="block text-lg font-black leading-none font-display"
        >
          {label}
        </label>
      </div>
    </div>
  )
);

export { Radio };
