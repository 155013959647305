import * as React from 'react';
import type { DeepMap, FieldError } from 'react-hook-form';

type ErrorMessageProps = {
  errors: DeepMap<Record<string, unknown>, FieldError>;
  label: string;
  name: string;
};

function ErrorMessage({
  errors,
  label,
  name,
}: ErrorMessageProps): React.ReactElement | null {
  const hasErrors = Boolean(errors?.[name]);
  return hasErrors ? (
    <div role="alert" className="block text-sm font-bold text-pink">
      {label} is a required field
    </div>
  ) : null;
}

export { ErrorMessage };
