import { ChevronDownIcon } from '@heroicons/react/outline';
import * as React from 'react';
import type { DeepMap, FieldError } from 'react-hook-form';

import { ErrorMessage } from './error-message';

type SelectProps = {
  errors: DeepMap<Record<string, unknown>, FieldError>;
  label: string;
  name: string;
  options: string[];
  required?: boolean;
} & (
  | { description: string; descriptionId: string }
  | { description?: never; descriptionId?: never }
);

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      description,
      descriptionId,
      errors,
      label,
      name,
      options,
      required,
      ...rest
    },
    ref
  ) => {
    const hasErrors = Boolean(errors?.[name]);
    return (
      <div className="flex flex-wrap justify-between">
        <label htmlFor={name} className="block text-lg font-black font-display">
          {label}
        </label>
        {description ? (
          <span id={descriptionId} className="text-sm text-gray-500">
            {description}
          </span>
        ) : null}
        <div className="relative w-full mt-1">
          <select
            aria-describedby={description ? descriptionId : undefined}
            aria-invalid={hasErrors}
            ref={ref}
            id={name}
            name={name}
            required={required}
            style={{ backgroundImage: 'none' }}
            className="mt-0 block w-full px-0.5 border-0 rounded-t-lg border-b-2 border-teal bg-cream focus:ring-0 focus:border-teal focus:bg-white pb-0 font-bold text-pink uppercase"
            {...rest}
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div
            aria-hidden
            className="absolute inset-0 flex items-center justify-end pr-2 pointer-events-none"
          >
            <ChevronDownIcon className="w-6 h-6 text-pink" />
          </div>
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </div>
    );
  }
);

export { Select };
