import * as React from 'react';
import { useForm } from 'react-hook-form';

import { ErrorMessage } from './form-elements/error-message';
import { Input } from './form-elements/input';
import { NetlifyForm } from './form-elements/netlify-form';
import { Radio } from './form-elements/radio';
import { Select } from './form-elements/select';
import { Textarea } from './form-elements/textarea';

interface FormData {
  full_name: string;
  email_address: string;
  business_name: string;
  contact_number: string;
  subject: '' | 'SEO Packages' | 'Website and SEO' | 'Website' | 'Help';
  seo_package:
    | 'Local Starter Package'
    | 'Performance Package'
    | 'Visibility Package'
    | 'Enterprise'
    | 'Other';
  ecommerce_package: 'Yes' | 'No';
  where_do_you_see_your_business_in_12_months: string;
  message: string;
}

function ContactForm(): JSX.Element {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onBlur', reValidateMode: 'onChange' });

  const subject = watch('subject');

  const showSeoPackage =
    subject === 'SEO Packages' || subject === 'Website and SEO';

  const showECommercePackage =
    subject === 'Website' || subject === 'Website and SEO';

  return (
    <div
      id="contact"
      className="relative px-4 mt-4 sm:mt-6 lg:mt-8 sm:px-6 lg:px-8"
    >
      <div aria-hidden className="absolute inset-y-0 left-0 w-1/2 bg-cream" />
      <div className="relative block px-4 py-12 rounded-2xl sm:px-6 lg:px-8 bg-cream">
        <div className="w-full max-w-4xl mx-auto">
          <NetlifyForm
            handleSubmit={handleSubmit}
            className="sm:flex sm:space-x-8"
          >
            <div className="grid w-full gap-8">
              <Input
                label="Full Name"
                autoComplete="name"
                {...register('full_name', { required: true })}
                errors={errors}
              />
              <Input
                label="Email Address"
                autoComplete="email"
                {...register('email_address', { required: true })}
                errors={errors}
              />
              <Input
                label="Business Name"
                autoComplete="organization"
                {...register('business_name', { required: true })}
                errors={errors}
              />
              <Input
                label="Business Phone"
                type="tel"
                autoComplete="tel"
                {...register('contact_number', { required: true })}
                errors={errors}
              />
              <Select
                label="Subject"
                options={[
                  '',
                  'SEO Packages',
                  'Website and SEO',
                  'Website',
                  'Help',
                ]}
                {...register('subject', { required: true })}
                errors={errors}
              />
              <fieldset hidden={!showSeoPackage} disabled={!showSeoPackage}>
                <legend className="block text-lg font-black font-display">
                  Pick SEO package
                </legend>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <Radio
                    id="local_starter_package"
                    label="Local Starter Package"
                    {...register('seo_package', {
                      required: showSeoPackage,
                    })}
                  />
                  <Radio
                    id="performance-package"
                    label="Performance Package"
                    {...register('seo_package', {
                      required: showSeoPackage,
                    })}
                  />
                  <Radio
                    id="visibility-package"
                    label="Visibility Package"
                    {...register('seo_package', {
                      required: showSeoPackage,
                    })}
                  />
                  <Radio
                    id="enterprise"
                    label="Enterprise"
                    {...register('seo_package', {
                      required: showSeoPackage,
                    })}
                  />
                  <Radio
                    id="other"
                    label="Other"
                    {...register('seo_package', {
                      required: showSeoPackage,
                    })}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="seo_package"
                  label="SEO package"
                />
              </fieldset>
              <fieldset
                hidden={!showECommercePackage}
                disabled={!showECommercePackage}
              >
                <legend className="block text-lg font-black font-display">
                  Are you interested in an E-Commerce package?
                </legend>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex mt-2 space-x-4">
                    <Radio
                      id="ecommerce_package_yes"
                      label="Yes"
                      {...register('ecommerce_package', {
                        required: showECommercePackage,
                      })}
                    />
                    <Radio
                      id="ecommerce_package_no"
                      label="No"
                      {...register('ecommerce_package', {
                        required: showECommercePackage,
                      })}
                    />
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="ecommerce_package"
                  label="Are you interested in an E-Commerce package?"
                />
              </fieldset>
              <Input
                label="Where do you see your business in 12 months?"
                autoComplete="off"
                {...register('where_do_you_see_your_business_in_12_months', {
                  required: true,
                })}
                errors={errors}
              />
              <Textarea
                label="Message"
                rows={3}
                {...register('message', { required: true })}
                errors={errors}
              />
            </div>
            <button
              type="submit"
              className="flex self-end p-4 mt-8 text-lg font-semibold sm:mt-0 rounded-2xl bg-pink text-cream"
            >
              Send
            </button>
          </NetlifyForm>
        </div>
      </div>
    </div>
  );
}

export { ContactForm };
