import * as React from 'react';
import type { DeepMap, FieldError } from 'react-hook-form';

import { ErrorMessage } from './error-message';

type InputProps = {
  autoComplete?: string;
  errors: DeepMap<Record<string, unknown>, FieldError>;
  label: string;
  name: string;
  required?: boolean;
  type?: 'text' | 'email' | 'number' | 'password' | 'search' | 'tel';
  onDark?: boolean;
} & (
  | { description: string; descriptionId: string }
  | { description?: never; descriptionId?: never }
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoComplete,
      description,
      descriptionId,
      errors,
      label,
      name,
      required,
      onDark,
      type = 'text',
      ...rest
    },
    ref
  ) => {
    const hasErrors = Boolean(errors?.[name]);

    return (
      <div className="flex flex-wrap justify-between">
        <label htmlFor={name} className="block text-lg font-black font-display">
          {label}
        </label>
        {description ? (
          <span id={descriptionId} className="text-sm text-gray-500">
            {description}
          </span>
        ) : null}
        <ErrorMessage errors={errors} name={name} label={label} />
        <div className="w-full mt-1">
          <input
            aria-describedby={description ? descriptionId : undefined}
            aria-invalid={hasErrors}
            ref={ref}
            id={name}
            name={name}
            required={required}
            type={type}
            autoComplete={autoComplete}
            className={`mt-0 block w-full px-0.5 border-0 rounded-t-lg border-b-2  focus:ring-0  pb-0 ${
              onDark
                ? 'border-cream text-cream bg-teal focus:bg-teal focus:border-cream'
                : 'border-teal bg-cream focus:bg-cream focus:border-teal'
            }`}
            {...rest}
          />
        </div>
      </div>
    );
  }
);

export { Input };
